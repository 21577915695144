import '../css/project.css';
import { initFlowbite } from 'flowbite';
import { DataTable } from 'simple-datatables';
import 'htmx.org';

initFlowbite();


if (document.getElementById('search-table') && DataTable !== undefined) {
  new DataTable("#search-table", {
    searchable: true,
    sortable: true,
    tableRender: (_data, table, type) => {
      if (type === "print") {
        return table
      }
      const tHead = table.childNodes[0]
      const filterHeaders = {
        nodeName: "TR",
        attributes: {
          class: "search-filtering-row"
        },
        childNodes: tHead.childNodes[0].childNodes.map(
          (_th, index) => ({
            nodeName: "TH",
            childNodes: [{
              nodeName: "INPUT",
              attributes: {
                class: "datatable-input",
                type: "search",
                "data-columns": "[" + index + "]"
              }
            }]
          })
        )
      }
      tHead.childNodes.push(filterHeaders)
      return table
    }
  });
}
